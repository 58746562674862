@font-face {
    font-family: nodesystem;
    src: url(/static/media/nodesystem.6f572071.otf) format("woff2");
}

@font-face {
    font-family: reason01;
    src: url(/static/media/reason_01.40bcdc62.otf) format("woff");
}

@font-face {
    font-family: reason02;
    src: url(/static/media/reason_02.c561dcb1.woff) format("woff");
}

@font-face {
    font-family: reason03;
    src: url(/static/media/reason_03.53d425c5.woff) format("woff");
}

@font-face {
    font-family: reason04;
    src: url(/static/media/reason_04.d5490685.woff) format("woff");
}

@font-face {
    font-family: reason05;
    src: url(/static/media/reason_05.63601136.woff) format("woff");
}

::-moz-selection {
  color: #000;
  background: #40FF51;
}

::selection {
  color: #000;
  background: #40FF51;
}

body {
  margin: 0;
  padding: 0;
  font-family: nodesystem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

#root{
  height: 0px;
  position: fixed;
}




html:hover {
  .cursor {
    opacity: 1;
  }
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  opacity: 1;
  mix-blend-mode: difference;
  -webkit-transition: opacity .2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: opacity .2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  pointer-events: none;

  :hover {
    .cursor-inner {
      -webkit-transform: scale(.5);
              transform: scale(.5);
      opacity: 0;
    }

    .cursor-outer {
      -webkit-transform: scale(1.4);
              transform: scale(1.4);
      border-color: #fff;
      opacity: 1;
    }
  }
}

.cursor-move-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-top: -3px;
  margin-left: -3px;
}

.cursor-move-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cursor-inner {
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), -webkit-transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transition: opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), -webkit-transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transition: transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), -webkit-transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.cursor-outer {
  display: block;
  width: 100%;
  height: 100%;
  border: .5px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-transition: border .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), -webkit-transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transition: border .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), -webkit-transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transition: border .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: border .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity .4s cubic-bezier(0.165, 0.840, 0.440, 1.000), -webkit-transform .4s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.cursor-text {
  letter-spacing: .7px;
  font-size: 9px;
  width: 70px;
  position: absolute;
  color: white;
  margin-left: 40px;
  margin-top: -7px;
}

button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  border-bottom: 2px solid #000;
  border-radius: 0;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: border .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: border .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

  :hover,
  :focus {
    border-color: #fff;
  }
}

.active > * {
  display: none;
  background: url(/static/media/oval.48759c25.svg) no-repeat;
  background-position: center;
  width: 8px;
  height: 8px;
  font-size: 0px!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  margin-left: 0px;
  -webkit-transform: translate(0,-8px);
          transform: translate(0,-8px);
  display: inline-block;
  color: #F6F6F6!important;
  line-height: 0%!important;
}

